/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-this-alias */

import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PPGraph from './GraphClass';
import PPNode from './NodeClass';
import { DashboardWidgetHeader } from '../components/GraphOverlayDashboard';
import UpdateBehaviourClass from './UpdateBehaviourClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import * as styles from '../utils/style.module.css';
import {
  CustomArgs,
  Layoutable,
  TNodeSource,
  TRgba,
  WidgetSize,
} from '../utils/interfaces';
import {
  PIXI_TRANSPARENT_ALPHA,
  NINE_SLICE_SHADOW,
  NODE_MARGIN,
  NODE_CORNERRADIUS,
  NODE_SOURCE,
  MAIN_COLOR,
} from '../utils/constants';

function pixiToContainerNumber(value: number) {
  return `${Math.round(value)}px`;
}

const blurAmount = 48;

const widgetSize = {
  w: 6,
  h: 6,
  minW: 1,
  minH: 2,
};

export default abstract class HybridNode2 extends PPNode implements Layoutable {
  root: Root;
  static: HTMLElement;
  staticRoot: Root;
  container: HTMLElement;
  initialData: any;
  shadowPlane: PIXI.NineSliceSprite;
  listenId: string[] = [];
  prevWasFocused = false;
  mounted = false;
  prevInputObject = undefined; // hack...

  constructor(name: string, customArgs?: CustomArgs) {
    super(name, {
      ...customArgs,
    });

    this.initialData = customArgs?.initialData;
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    await PIXI.Assets.load(NINE_SLICE_SHADOW);
    const texture = PIXI.Texture.from(NINE_SLICE_SHADOW);
    this.shadowPlane = new PIXI.NineSliceSprite({
      texture,
      leftWidth: blurAmount,
      topHeight: blurAmount,
      rightWidth: blurAmount,
      bottomHeight: blurAmount,
    });
    this.addChildAt(this.shadowPlane, 0);
    await super.onNodeAdded(source);
    if (this.shouldFocusWhenNew() && source === NODE_SOURCE.NEW) {
      setTimeout(() => {
        this.onEditButtonClick();
        this.focus();
      }, 500); // timeout is problematic
    }
  }

  public shouldFocusWhenNew(): boolean {
    return false;
  }

  protected focus(): void {}

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(true, true, false, 1000, this);
  }

  getDefaultWidgetSize() {
    return widgetSize;
  }

  isLayoutable(): boolean {
    return true;
  }

  getDashboardId(): string {
    return `NODE_${this.id}`;
  }

  getDashboardName(): string {
    return this.nodeName;
  }

  public getWidgetSize(): WidgetSize {
    return this.getDefaultWidgetSize();
  }

  getDashboardWidget(index, randomMainColor): any {
    return (
      <DashboardWidgetContainerHybridNode
        property={this}
        index={index}
        randomMainColor={randomMainColor}
      />
    );
  }

  getRelatedNode(): PPNode {
    return this;
  }
  protected visualOffsetXY(x: number, y: number) {
    super.visualOffsetXY(x, y);
    this.redraw();
  }

  redraw() {
    if (this.destroyed) {
      return;
    }
    const scale = PPGraph.currentGraph.viewportScaleX;
    const screenPointBackgroundRectTopLeft =
      this.screenPointBackgroundRectTopLeft();
    const screenX = screenPointBackgroundRectTopLeft.x;
    const screenY = screenPointBackgroundRectTopLeft.y;
    if (!this.container) {
      if (this.prevInputObject !== undefined && this.isVisible()) {
        this.createContainerComponent(this.prevInputObject);
      }
      return;
    }

    if (this.isVisible()) {
      if (this.container.style.display !== 'block') {
        this.container.style.display = 'block';
      }

      const newScale = scale.toPrecision(3);
      const newLeft = pixiToContainerNumber(screenX);
      const newTop = pixiToContainerNumber(screenY);

      // Batch DOM updates
      if (
        this.container.style.transform !== `scale(${newScale})` ||
        this.container.style.left !== newLeft ||
        this.container.style.top !== newTop
      ) {
        // Use requestAnimationFrame to batch updates
        requestAnimationFrame(() => {
          this.container.style.transform = `scale(${newScale})`;
          this.container.style.left = newLeft;
          this.container.style.top = newTop;
        });
      }
    } else {
      if (this.container.style.display !== 'none') {
        this.container.style.display = 'none';
      }
    }
  }

  public fadeAllNonPIXIParts(alpha: number): void {
    if (this.container) {
      this.container.style.opacity = alpha.toString();
    }
  }

  // this function can be called for hybrid nodes, it
  // • creates a container component
  // • adds the onNodeDragOrViewportMove listener to it
  // • adds a react parent component with props
  createContainerComponent(reactProps, customStyles = {}): HTMLElement {
    const reactElement = document.createElement('div');
    this.container = document
      .getElementById('container')
      .appendChild(reactElement);
    this.root = createRoot(this.container!);
    this.container.id = `Container-${this.id}`;

    const scale = PPGraph.currentGraph.viewportScaleX;
    this.container.classList.add(styles.hybridContainer);
    Object.assign(this.container.style, customStyles);

    // set initial position
    this.container.style.width = `${this.nodeWidth}px`;
    this.container.style.height = `${this.nodeHeight}px`;
    this.container.style.transform = `scale(${scale}`;

    this.onViewportPointerUpHandler = this.onViewportPointerUp.bind(this);

    // when the Node is removed also remove the react component and its container
    this.onNodeRemoved = () => {
      this.removeContainerComponent(this.container, this.root);
      this.mounted = false;
    };

    this.mounted = true;
    // render react component
    this.renderReactComponent(
      {
        ...reactProps,
      },
      this.root,
      this,
    );

    this.redraw();

    return this.container;
  }

  abstract getParentComponent(inputObject: any): any;

  public hasFocus(): boolean {
    return PPGraph.currentGraph.focusedNode?.id == this.id;
  }

  // the render method, takes a component and props, and renders it to the page
  renderReactComponent = (
    props: {
      [key: string]: any;
    },
    root = this.root,
    node: HybridNode2 = this,
  ): void => {
    if (this.mounted) {
      root.render(
        <>
          <this.getParentComponent
            initialData={this.initialData} // positioned before the props so it can be overwritten by them
            {...props}
            id={this.id}
            selected={this.selected}
            randomMainColor={MAIN_COLOR}
            node={node}
            isFocused={this.hasFocus()}
          />
          <HybridNodeOverlay node={this} />
        </>,
      );
    } else {
      this.createContainerComponent(props);
    }
  };

  protected async onHybridNodeExit(): Promise<void> {}

  removeContainerComponent(container: HTMLElement, root: Root): void {
    root.unmount();
    document.getElementById('container').removeChild(container);
  }

  protected onViewportMove(): void {
    this.redraw();
  }

  setPosition(x: number, y: number, isRelative = false): void {
    super.setPosition(x, y, isRelative);
    this.onViewportMove(); // trigger this once, so the react components get positioned properly
  }

  async tick(currentTime: number, deltaTime: number): Promise<void> {
    super.tick(currentTime, deltaTime);
    const currFocus = this.hasFocus();
    if (currFocus && !this.prevWasFocused) {
      await this.onEditButtonClick();
    } else if (!currFocus && this.prevWasFocused) {
      await this.unFocus();
    }
  }

  resizeAndDraw(
    width = this.nodeWidth,
    height = this.nodeHeight,
    maintainAspectRatio = false,
  ): void {
    super.resizeAndDraw(width, height, maintainAspectRatio);
    if (this.container) {
      this.container.style.width = `${width}px`;
      this.container.style.height = `${height}px`;
    }
  }

  async makeEditable(): Promise<void> {
    // register hybrid nodes to listen to outside clicks
    this.container.classList.add(styles.hybridContainerFocused);
    this.drawBackground();
    await this.execute();
  }

  async onEditButtonClick(): Promise<void> {
    this.listenId.push(
      InterfaceController.addListener(
        ListenEvent.GlobalPointerUp,
        this.onViewportPointerUpHandler,
      ),
    );
    this.listenId.push(
      InterfaceController.addListener(
        ListenEvent.EscapeKeyUsed,
        this.onViewportPointerUpHandler,
      ),
    );
    PPGraph.currentGraph.focusedNode = this;
    this.prevWasFocused = true;
    await this.makeEditable();
  }

  public onNodeDoubleClick = (event) => {
    if (this.getActivateByDoubleClick()) {
      this.onEditButtonClick();
    }
  };

  public async unFocus() {
    this.prevWasFocused = false;
    PPGraph.currentGraph.focusedNode = undefined;

    this.listenId.forEach((id) => InterfaceController.removeListener(id));
    this.listenId = [];
    // this allows to zoom and drag when the hybrid node is not selected
    this.container.classList.remove(styles.hybridContainerFocused);
    this.drawBackground();
    await this.execute();
    await this.onHybridNodeExit();
  }

  public getShrinkOnSocketRemove(): boolean {
    return false;
  }

  public getActivateByDoubleClick(): boolean {
    return true;
  }

  protected getShowLabels(): boolean {
    return false;
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    this.prevInputObject = inputObject;

    if (this.isVisible()) {
      requestAnimationFrame(() => {
        this.renderReactComponent(inputObject);
        return this.id;
      });
    }
  }

  getOpacity(): number {
    return PIXI_TRANSPARENT_ALPHA;
  }

  public drawBackground(): void {
    this._BackgroundGraphicsRef.roundRect(
      NODE_MARGIN,
      0,
      this.nodeWidth,
      this.nodeHeight,
      this.getRoundedCorners() ? NODE_CORNERRADIUS : 0,
    );
    if (this.hasFocus()) {
      this.shadowPlane.x = -blurAmount + NODE_MARGIN;
      this.shadowPlane.y = -blurAmount;
      this.shadowPlane.width = this.nodeWidth + blurAmount * 2;
      this.shadowPlane.height = this.nodeHeight + blurAmount * 2;
      this.shadowPlane.visible = true;
    } else {
      this.shadowPlane.visible = false;
    }
    this._BackgroundGraphicsRef.fill({
      color: this.getColor().hexNumber(),
      alpha: this.getOpacity(),
    });
  }

  onNodeRemoved = (): void => {
    this.listenId.forEach((id) => InterfaceController.removeListener(id));
  };
}

type HybridNodeOverlayProps = {
  node: HybridNode2;
};

const HybridNodeOverlay: React.FunctionComponent<HybridNodeOverlayProps> = (
  props,
) => {
  const node = props.node;
  return (
    !node.hasFocus() && (
      <>
        {node.getActivateByDoubleClick() && (
          <Button
            data-cy={`${node.id}-edit-hybridnode-btn`}
            title={'Click to edit OR Double click node'}
            className={styles.hybridContainerEditButton}
            size="small"
            onClick={() => node.onEditButtonClick()}
            color="primary"
            sx={{
              background: MAIN_COLOR,
              color: TRgba.fromString(MAIN_COLOR).getContrastTextColor().hex(),
            }}
          >
            <EditIcon sx={{ fontSize: '16px' }} />
          </Button>
        )}
        {node.isLayoutable() && (
          <Button
            data-cy={`${node.id}-add-hybridnode-to-dashboard-btn`}
            title={'Add to dashboard'}
            className={styles.hybridContainerAddToDashboardButton}
            size="small"
            onClick={() => InterfaceController.onAddToDashboard(node)}
            color="primary"
            sx={{
              background: MAIN_COLOR,
              color: TRgba.fromString(MAIN_COLOR).getContrastTextColor().hex(),
            }}
          >
            <DashboardCustomizeIcon sx={{ fontSize: '16px' }} />
          </Button>
        )}
      </>
    )
  );
};

type DashboardWidgetContainerHybridNodeProps = {
  property: HybridNode2;
  index: number;
  randomMainColor: string;
};

export const DashboardWidgetContainerHybridNode: React.FunctionComponent<
  DashboardWidgetContainerHybridNodeProps
> = (props) => {
  const [showDashboard, setShowDashboard] = useState(
    InterfaceController.getOverlayState().dashboard.visible,
  );
  const [executionCount, setExecutionCount] = useState(0);

  useEffect(() => {
    setShowDashboard(InterfaceController.getOverlayState().dashboard.visible);
  }, [InterfaceController.getOverlayState().dashboard.visible]);

  useEffect(() => {
    const executionListener = () => {
      setExecutionCount((prevCount) => prevCount + 1);
    };
    props.property.addExecutionListener(executionListener);

    return () => {
      props.property.removeExecutionListener(executionListener);
    };
  }, [props.property]);

  return (
    <Box
      id={`inspector-node-${props.property.getName()}`}
      sx={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <DashboardWidgetHeader
        key={`SocketHeader-${props.property.getName()}`}
        property={props.property}
        selectedNode={props.property}
        shouldBeLocked={false}
      />
      <Box
        sx={{
          height: 'calc(100% - 24px)',
          overflow: 'auto',
        }}
      >
        <props.property.getParentComponent
          initialData={props.property.initialData} // positioned before the props so it can be overwritten by them
          {...HybridNode2.remapInput(props.property.inputSocketArray)}
          id={props.property.id}
          selected={props.property.selected}
          isFocused={props.property.hasFocus()}
          randomMainColor={props.randomMainColor}
          node={props.property}
          showDashboard={showDashboard}
          inDashboard={true}
          executionCount={executionCount}
        />
      </Box>
    </Box>
  );
};
